<style scoped>
.form-param.param-filial {
  border: 1px solid #ccc;
  padding: 10px;
}
.form-param.param-filial label {
  font-size: 0.8rem;
}
.title {
  margin-bottom: 20px;
}
</style>

<template>
  <modal name="parametroEdit" height="auto" width="600" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <h2 class="title">
          Alterar {{ descSingular }}
          <br>
          <br>
          <small class="font-sm">
            <strong>{{ parametro ? parametro.desc : '' }}</strong>
          </small>
          <br>
          <small class="font-sm">Chave: {{ parametro.chave }}</small>
        </h2>
        <div class="form-group">
          <div class="form-group form-param">
            <label v-if="usaValorPorFilial">
              Valor padrão (utilizado quando não possui valor na filial)
            </label>
            <!-- Parâmetros tradicionais -->
            <TipoInput
              v-if="parametro.tipo !== 'TYPE_KEYVALUE'"
              v-model="parametro.valor"
              :parametro="parametro"
              :tipo="parametro.tipo"
              :opcoes="parametro.opcoes"
              :valor="parametro.valor"
            />

            <!-- Parâmetros KeyValue geram loop de vários inputs-->
            <TipoInput
              v-if="parametro.tipo === 'TYPE_KEYVALUE'"
              v-for="(opcaoTipo, opcaoNome) in parametro.opcoes"
              v-model="parametro.valor[opcaoNome]"
              :key="opcaoNome"
              :parametro="{
                valor: parametro.valor[opcaoNome],
                label: opcaoNome,
              }"
              :tipo="opcaoTipo"
              :opcoes="parametro.opcoes"
            />
          </div>
          <div v-if="usaValorPorFilial"
               v-show="exibeValoresFiliais"
               v-for="(filial, index) in filiais"
               class="form-group form-param param-filial">
            <label for>Filial {{filial.id}} - {{filial.razSoc}}</label>
            <TipoInput
              v-if="exibeValoresFiliais"
              v-model="parametro.filiais[filial.id]"
              :parametro="parametro"
              :tipo="parametro.tipo"
              :opcoes="parametro.opcoes"
              :valor="parametro.valor"
            />
          </div>
          <button
            v-if="usaValorPorFilial"
            type="button"
            class="button button-primary"
            style="margin-top: 10px"
            v-on:click="adicionarValoresFiliais"
          >
            {{exibeValoresFiliais ? `Manter apenas valor padrão` : `Adicionar valores por filial`}}
          </button>
        </div>
    </div>
    </div>
    <div class="modal-footer">
      <button
          :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
          v-on:click="update"
          :disabled="validation.hasError()"
      >
        Atualizar
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>
<script>
import { Validator } from "simple-vue-validator";
import TipoInput from "./TipoInput";
import _isEmpty from "lodash/isEmpty";
import CloseModal from "../../components/CloseModal";

export default {
  name: "parametroEdit",
  components: { TipoInput, CloseModal },
  props: [
    "update",
    "close",
    "parametro",
    "descSingular",
    "filiais"
  ],
  computed: {
    usaValorPorFilial() {
      return this.parametro.classe === 'PARAM';
    }
  },
  data() {
    return {
      exibeValoresFiliais: 0,
    };
  },
  watch: {
    "parametro.chave"() {
      this.exibeValoresFiliais = !_isEmpty(this.parametro.filiais);
    },
  },
  methods: {
    adicionarValoresFiliais() {
      this.exibeValoresFiliais = !this.exibeValoresFiliais;

      if (!this.exibeValoresFiliais) {
        this.parametro.filiais = {};
      }
    },
  },
  validators: {
    "parametro.valor": function(value) {
      return this.parametro.tipo !== "TEXT" && this.parametro.tipo !== "IMAGE"
        ? Validator.value(value).required()
        : Validator.value(value);
    },
  },
};
</script>
