<template>
  <div v-if="tipo === 'SELECT'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <select class="form-control" v-bind:value="value" v-on:input="handleChange">
      <option v-for="(chave, valor) in opcoes" :value="valor" v-bind:key="valor">{{ chave }}</option>
    </select>
  </div>

  <div v-else-if="tipo === 'TEL'">
    <label>Número</label>
    <input type="text" class="form-control" v-model="parametro.valor.valor">
    <br>
    <label>Título</label>
    <input type="text" class="form-control" v-model="parametro.valor.label">
  </div>

  <div v-else-if="tipo === 'EMAIL'">
    <label>Endereço de Email</label>
    <input type="email" class="form-control" v-model="parametro.valor.valor">
    <br>
    <label>Título</label>
    <input type="text" class="form-control" v-model="parametro.valor.label">
  </div>

  <div v-else-if="tipo === 'WYSIWYG'">
    <ckeditor type="classic" v-bind:value="value" v-on:input="handleChange" :parametro="editorparametro" :editor="editor"></ckeditor>
  </div>

  <div v-else-if="tipo === 'JSON'">
    <div class="codemirror">
      <codemirror v-bind:value="value" v-on:input="handleChange" :options="code_options"></codemirror>
    </div>
  </div>

  <div v-else-if="tipo === 'TRANSLATE'">
    <label>Título</label>
    <input type="text" class="form-control" v-model="parametro.valor.label">
    <br>
    <label>Descrição</label>
    <textarea class="form-control" rows="5" v-model="parametro.valor.valor"></textarea>
  </div>

  <div v-else-if="tipo === 'TEXTAREA'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <textarea class="form-control" rows="5" v-bind:value="value" v-on:input="handleChange"></textarea>
  </div>

  <div v-else-if="tipo === 'INTEGRATION'">
    <select class="form-control" v-model="parametro.valor.tipo">
      <option value="">Nenhuma</option>
      <option v-for="(chave, valor) in opcoes" :value="valor" v-bind:key="valor">{{ chave }}</option>
    </select>

    <div v-for="(tipo, chave) in parametro.schemas[parametro.valor.tipo]" v-if="chave !== 'tipo'">
      <br>
      <label>{{ chave | label }}</label>
      <div v-if="tipo === 'BOOLEAN'">
        <select class="form-control" v-bind:value="value" v-model="parametro.valor[chave]">
          <option value="1" v-bind:selected="+parametro.valor[chave] === 1">Sim</option>
          <option value="0" v-bind:selected="+parametro.valor[chave] === 0">Não</option>
        </select>
      </div>
      <div v-else>
        <input class="form-control" v-model="parametro.valor[chave]"/>
      </div>
    </div>
  </div>

  <div v-else-if="tipo === 'BOOLEAN'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <select class="form-control" v-bind:value="+value" v-on:input="handleChange">
      <option value="1" v-bind:selected="+parametro.valor === 1">Sim</option>
      <option value="0" v-bind:selected="+parametro.valor === 0">Não</option>
    </select>
  </div>

  <div v-else-if="tipo === 'NUMERIC'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <input type="number" class="form-control"  v-bind:value="value" v-on:input="handleChange" />
  </div>

  <div v-else-if="tipo === 'CURRENCY'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <money class="form-control"  v-bind:value="value" v-on:input="handleChange" v-bind="monetary"></money>
  </div>

  <div v-else-if="tipo === 'PERCENT'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <money class="form-control" v-bind:value="value" v-on:input="handleChange" v-bind="percent"></money>
  </div>

  <div v-else-if="tipo === 'IMAGE'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <image-picker
        placeholder="Colar URL ou clique ao lado para upload"
        v-bind:value="value"
        v-on:input="handleChange" />
    <img v-if="value" :src="value" alt="Preview" class="thumbnail thumbnail-small"/>

  </div>

  <div v-else-if="tipo instanceof Object && tipo.tipo === 'SELECT'">
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <select class="form-control" v-bind:value="value" v-on:input="handleChange">
      <option v-for="(chave, valor) in tipo.opcoes" :value="valor" v-bind:key="valor">{{ chave }}</option>
    </select>
  </div>

  <div v-else>
    <label v-if="parametro.label">{{ parametro.label | label }}</label>
    <input type="text" class="form-control"  v-bind:value="value" v-on:input="handleChange">
  </div>

</template>

<script>
import ImagePicker from "../../components/ImagePicker";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js";

import { codemirror } from 'vue-codemirror'

// theme css
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'

// language js
import'codemirror/addon/selection/active-line.js'
import 'codemirror/mode/xml/xml.js'

// autoCloseTags
import'codemirror/addon/edit/closetag.js'

export default {
  name: 'tipoInput',
  components: {
    ImagePicker,
    codemirror
  },
  props: [
    "parametro",
    "value",
    "tipo",
    "opcoes"
  ],
  filters: {
    label(label) {
      return label.toUpperCase().replace(/\_/g, " ")
    }
  },
  data() {
    return {
      monetary: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        masked: false
      },
      percent: {
        decimal: ',',
        thousands: '.',
        suffix: '% ',
        masked: false
      },
      editor: ClassicEditor,
      editorConfig: {
        language: "pt-br",
        toolbar: ["heading", "bold", "italic", "link"]
      },
      code_options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/html',
        lineWrapping: true,
        theme: 'monokai',
        showCursorWhenSelecting: true,
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
      }
    };
  },
  methods: {
    handleChange(event) {
      if (event instanceof Object) {
        this.$emit('input', event.target.value)
      } else {
        this.$emit('input', event)
      }
    }
  }
}
</script>
