<template>
  <div>
    <Loading :isActive="loading"/>
    <component
      :is="currentComponent"
      :parametro="currentParam"
      :update="updateParam"
      :close="closeEdit"
      :descSingular="descSingular"
      :filiais="filiais"
    />
    <ModalInfoMarkdown
      :titulo="currentParam.desc"
      :conteudo="currentParam.documentacao"
      :close="closeInfo"
    />
    <crud-base
      :url="url"
      :columns="columns"
      :enableSearch="true"
      :show-actions="true"
      :reload="shouldReload"
      @reload="setReload"
    >
      <template v-slot:descricao="{model}">
        <template v-if="model.tipo === 'TRANSLATE'">
          <div>
            {{ model.desc }}
            <br>
            <br>
          </div>
          <div>
            <strong>{{ model.valor ? model.valor.label : 'Título não informado' }}</strong>
          </div>
          <div>
            <small>{{ model.valor ? model.valor.valor : 'Descrição não informada' }}</small>
          </div>

        </template>
        <template v-else>
          {{ model.desc }}
          <small>{{ model.valor | tipo(model.tipo, model.opcoes) }}</small>
        </template>
      </template>

      <template v-slot:action-buttons="{model}">
        <CButton
            size="sm"
            v-show="model.has_documentacao"
            color="info"
            @click="openInfo(model)" title="Informações detalhadas">
          <fa-icon icon="info-circle"/>
        </CButton>

        <CButton color="primary" size="sm" @click="openEdit(model)">
          <fa-icon icon="pencil-alt"/>
        </CButton>

        <LogAlteracaoDados
          tabela="parametro_geral"
          :chave="model.chave"
        />
      </template>

    </crud-base>
  </div>
</template>

<script>

import Edit from './Edit';
import ModalInfoMarkdown from '@/components/ModalInfoMarkdown';

import {
  toDecimal,
  toMonetary
} from "@/helpers/utils";
import clone from "just-clone";
import {get, put} from "@/helpers/apiRequest";
import {loadCargas} from "@/helpers/cargas";
import config from "@/config";
import _orderBy from "lodash/orderBy";
import CrudBase from "../../components/crud/CrudBase";

export default {
  name: "ListaParametros",
  components: {
    Edit,
    ModalInfoMarkdown,
    CrudBase
  },
  props: [
    "url",
    "descPlural",
    "descSingular",
    "reload",
  ],
  data() {
    return {
      columns: [
        {label: 'Parâmetro', field: 'descricao', sortable: false, useSlot: true},
      ],
      loading: false,
      shouldReload: false,
      currentComponent: null,
      currentParam: {},
      filiais: [],
    };
  },
  filters: {
    tipo(valor, tipo, opcoes) {
      switch (tipo) {
        case 'WYSIWYG':
        case 'TYPE_KEYVALUE':
        case 'INTEGRATION':
        case 'JSON':
        case 'EMAIL':
        case 'TEL':
          return 'Parâmetro de múltiplas opções';

        case 'CURRENCY':
          return toMonetary(valor);

        case 'PERCENT':
          return toDecimal(valor);

        case 'SELECT':
          return opcoes[valor] || valor;

        case 'IMAGE':
        case 'BOOLEAN':
          if (valor) return 'Sim';
          return 'Não';

        default:
          return valor;
      }
    },
  },
  methods: {
    setReload(shouldReload) {
      this.shouldReload = shouldReload;
    },
    importComponent(componentName, modalName = null) {
      return import(`${componentName}`)
          .then((component) => {
            this.currentComponent = component.default;
            setTimeout(() => this.$modal.show(modalName || componentName), 0);
          });
    },
    openInfo(parametro) {
      this.currentParam = clone(parametro);
      this.loadParametroDetalhes();
      this.$modal.show('modalInfoMarkdown');
    },
    closeInfo() {
      this.$modal.hide('modalInfoMarkdown');
    },
    openEdit(parametro) {
      this.currentParam = clone(parametro);
      this
        .importComponent(`./Avancado/${parametro.chave}`, parametro.chave)
        .catch(() => this.importComponent('./Edit', 'parametroEdit'));
    },
    closeEdit() {
      this.$modal.hide(this.currentComponent.name);
    },
    loadParametroDetalhes() {
      this.loading = true;

      get(`/admin/parametros/${this.currentParam.chave}`)
          .then((json) => {
            this.currentParam = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    updateParam() {
      const param = {
        chave: this.currentParam.chave,
        valor: this.currentParam.valor,
        filiais: this.currentParam.filiais,
      };

      loadCargas(
          config.token(),
          () => {
            localStorage.setItem('revalidar_carga', 0);
          }
      );

      this.loading = true;
      put(`${this.url}/${param.chave}`, param)
          .then((json) => {
            this.$swal({
              title: 'Salvo com sucesso',
              text: json.message,
              showCancelButton: false,
              cancelButtonText: 'Fechar',
              showConfirmButton: true,
              icon: 'success',
            });
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: 'Falha ao Salvar',
              text: error.message,
              showCancelButton: true,
              cancelButtonText: 'Fechar',
              showConfirmButton: false,
              icon: 'error',
            });
          })
          .then(() => {
              this.loading = false;
              this.shouldReload = true;
          })
          .then(() => this.closeEdit());
    },
  },
  beforeMount() {
    const {filiais} = JSON.parse(localStorage.getItem('app'));
    this.filiais = _orderBy(filiais, 'id');
  }
}
</script>