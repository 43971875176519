<template>
  <modal name="modalInfoMarkdown" height="auto" width="900" :scrollable="true" :clickToClose="true">
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="titulo">
            <div class="table-responsive">
              <vue-markdown :source="conteudo"/>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseModal from './CloseModal';

export default {
  name: 'ModalInfoMarkdown',
  components: {
    CloseModal
  },
  props: [
    'titulo',
    'conteudo',
    'close',
  ],
};
</script>

<style scoped>

</style>
